import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiFacebook, FiTwitter, FiChevronLeft } from 'react-icons/fi';
import { AiOutlineGlobal } from 'react-icons/ai';

//components
import Footer from '../Footer';
import Bio from './Bio';
import Policies from './Policies';
import ContactInfo from './ContactInfo';
import Video from './Video';

//actions
import { fetchCandidateInfoById } from '../../redux/actions/Candidates';
import { RESET_CONTACT_INFO } from '../../redux/actions/ActionTypes';

//scss
import '../../sass/components/_candidate-landing.scss';

/**
   This component will describe the candidate profile
   @props {object} props - Props value
 */
const CandidateLanding = (props) => {
  const cityName = ( props?.match?.params?.city ); // from 3 to 1 item (ccb)
  const candidateId = props?.match?.params?.candidateId;
  const [key, setKey] = useState('bio');
  const [selectedCityObj, setSelectedCityObj] = useState({});
  const [candidateData, setCandidateData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const { candidateInfo } = useSelector((store) => store.candidate);
  const { cities } = useSelector((state) => state.city);
  const { theme } = useSelector((store) => store.global);
  const [mode, setMode] = useState(theme);

  /**
   * This will handle the changes in the candidateId and dispatch
   * @dependency {string} candidateId - The candidate Id
   * @dependency {object} dispatch - The Dispatch
   */
  useEffect(() => {
    if (candidateId) {
      dispatch(fetchCandidateInfoById(candidateId));
    }
  }, [candidateId, dispatch]);

  /**
   * This will handle clear candidateInfo on unmount
   */
  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_CONTACT_INFO,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (cityName && cities?.length > 0) {
      const ind = cities.findIndex((c) => c.name === cityName);
      if (ind >= 0) {
        setSelectedCityObj({ ...cities[ind] });
      }
    }
  }, [cityName, cities]);

  /**
   * This will handle the changes in the theme
   * @dependency {string} theme - The theme information
   */
  useEffect(() => {
    setMode(theme);
  }, [theme]);

  /**
   * This will handle the changes in the candidate info object
   * @dependency {object} candidateInfo - The candidate information
   */
  useEffect(() => {
    if (candidateInfo) {
      setCandidateData(candidateInfo);
    }
  }, [candidateInfo]);

  /**
   * This will bring the tab content to the top for clicked tab
   * @params {string} k - The selected tab event key
   */
  const handleTabSelect = (k) => {
    setKey(k);
    switch (k) {
      case 'bio':
        const bioDivId = document.getElementById('bio-div');
        const bioRect = bioDivId?.getBoundingClientRect();
        window.scrollTo(0, bioRect?.y - 68);
        break;
      case 'policy':
        const policyDivId = document.getElementById('policy-div');
        const policyRect = policyDivId?.getBoundingClientRect();
        window.scrollTo(0, policyRect?.y - 68);
        break;
      case 'contact':
        const contactDivId = document.getElementById('contact-div');
        const contactRect = contactDivId?.getBoundingClientRect();
        window.scrollTo(0, contactRect?.y - 68);
        break;
      case 'video':
        const videoDivId = document.getElementById('video-div');
        const videoRect = videoDivId?.getBoundingClientRect();
        window.scrollTo(0, videoRect?.y - 68);
        break;
      default:
        break;
    }
  };

  /**
   * Sends site visitor back to candidate list (city) when visitor navigates
   * to specific candidate page from the home page. If visitor
   * lands directly on candidate page from external link, they will be 
   * redirected to home page (ccb).
   */
  const handleBack = () => {
     if ( cityName && props?.history?.location?.state?.didNavigate ) {
       history.push(`/city/${cityName}`);
     } else {
       history.push(`/`);
     }
  };

  const initials = `${candidateData?.candidateName
    ?.split(' ')[0]
    .substr(0, 1)}${candidateData?.candidateName?.split(' ')[1].substr(0, 1)}`;

  const candidateNameArr = candidateData?.candidateName?.split(' ');

  return (
    <div className='mainsite'>
      <div className={`main ${mode}`}>
        <div className='main-page-wrap'>
          <div
            className={`profile-wrap pt-5 pb-5 d-flex  align-items-center ${mode}`}
            style={{
              backgroundImage: `url(${mode === 'light'
                ? selectedCityObj?.images?.web?.lightprofile
                : selectedCityObj?.images?.web?.darkprofile
                })`,
            }}
          >
            <div className='d-flex flex-wrap align-items-center justify-content-center image-wrap mb-3 '>
              {/*{isLoading && <Spinner className='loader' animation="border" role="status" />}*/}
              {candidateData &&
                Object.keys(candidateData).length > 0 &&
                (candidateData.profile ? (
                  <Image
                    className='profile-img'
                    width='243px'
                    height='243px'
                    src={candidateData?.profile}
                    roundedCircle
                  />
                ) : (
                  <div className={'avatar-circle'}>
                    <span className={'initials'}>{initials}</span>
                  </div>
                ))}
              {/* This Div is only for Mobile View */}
              <div className='social-icon d-md-none d-flex my-4'>
                {candidateData?.facebook && (
                  <a
                    href={candidateData?.facebook}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FiFacebook />
                  </a>
                )}
                {candidateData?.twitter && (
                  <a
                    href={candidateData?.twitter}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FiTwitter />
                  </a>
                )}
                {candidateData?.website && (
                  <a
                    href={candidateData?.website}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <AiOutlineGlobal />
                  </a>
                )}
              </div>
            </div>
            <div className='image-social-wrap'>
              <div className={`user-name ${mode}`}>
                {candidateNameArr?.[0] && (
                  <p className='m-0'>{candidateNameArr?.[0]}</p>
                )}
                {candidateNameArr?.[1] && (
                  <p className='m-0 ml-md-3'>{candidateNameArr?.[1]}</p>
                )}
                {candidateNameArr?.[2] && (
                  <p className='m-0 ml-md-3'>{candidateNameArr?.[2]}</p>
                )}
              </div>
              {/* This Div is only for Desktop View */}
              <div className={`d-none d-md-flex social-icon my-4 ${mode}`}>
                {candidateData?.facebook && (
                  <a
                    href={candidateData?.facebook}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FiFacebook />
                  </a>
                )}
                {candidateData?.twitter && (
                  <a
                    href={candidateData?.twitter}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FiTwitter />
                  </a>
                )}
                {candidateData?.website && (
                  <a
                    href={candidateData?.website}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <AiOutlineGlobal />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="main-tab-wrap candidateLanding--navBar"
        >
          {cityName && (
            <FiChevronLeft
              className={`forward-icon ${mode}`}
              onClick={handleBack}
            />
          )}
          <Tabs
            className={`tab-wrap justify-content-around ${mode}`}
            id=' controlled-tab-example'
            activeKey={key}
            onSelect={(k) => handleTabSelect(k)}

          >
            <Tab eventKey='bio' title='Bio' />
            {candidateData?.videoUrls && (
              <Tab eventKey='video' title='Videos' />
            )}
            <Tab eventKey='policy' title='Policies' />
            {candidateData?.email && (
              <Tab eventKey='contact' title='Contact Info' />
            )}
          </Tabs>
        </div>
        <div className='position-relative main-tab-wrap'>
          <div className='container-fluid'>
            <div className='col-lg-12'>
              <Bio
                description={candidateData?.bio}
                mode={mode}
                firstName={candidateData?.candidateName?.split(' ')?.[0]}
              />
              {candidateData?.videoUrls?.length > 0 && (
                <Video videoUrls={candidateData?.videoUrls} />
              )}
              {Object.keys(candidateData).length > 0 && (
                <Policies
                  policies={candidateData?.policies}
                  isProvided={candidateData?.isProvided}
                  policyHyperLink={candidateData?.policyhyperlink || null}
                  userName={candidateData?.candidateName?.split(' ')?.[0]}
                  mode={mode}
                  isContactVisible={candidateData?.email}
                />
              )}
              {candidateData?.email && (
                <ContactInfo
                  email={candidateData?.email}
                  mode={mode}
                  username={candidateNameArr[0]}
                  middleName={
                    candidateNameArr.length > 2 ? candidateNameArr[1] : ''
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CandidateLanding;
