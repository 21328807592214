import {
  FETCH_CITY,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_ERROR,
  FETCH_CITY_AND_POSITION,
  FETCH_CITY_AND_POSITION_ERROR,
  FETCH_CITY_AND_POSITION_SUCCESS,
  SET_SELECTED_CITY,
  ADD_CITY,
  ADD_CITY_SUCCESS,
  ADD_CITY_ERROR,
  UPDATE_CITY,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_ERROR,
} from '../ActionTypes';

import axios from '../../axios';

/**
 This action will fetch all cities
 */
export const fetchCities = () => (dispatch) => {
  dispatch({ type: FETCH_CITY });
  axios
    .get('/cities')
    .then((data) => {
      dispatch({
        type: FETCH_CITY_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FETCH_CITY_ERROR,
        payload: err,
      });
    });
};

/**
 This action will fetch all cities by grouing them on year
 */
 export const fetchCitiesWithPositions = () => (dispatch) => {
  dispatch({ type: FETCH_CITY_AND_POSITION });
  axios
    .get('/cities/city-positions')
    .then((data) => {
      dispatch({
        type: FETCH_CITY_AND_POSITION_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FETCH_CITY_AND_POSITION_ERROR,
        payload: err,
      });
    });
};

/**
 This action will save City Id and Position Id in redux store
 @args {string} cityId - City Id
 @args {string} name - City Name
 @args {string} positionId - Position Id
 */
export const setSelectedCityId =
  (cityId, name, positionId = '') =>
  (dispatch) => {
    localStorage.setItem('city', name);
    localStorage.setItem('position', positionId);
    dispatch({
      type: SET_SELECTED_CITY,
      payload: { cityId, positionId },
    });
  };

/**
 This action will add City
 @args {object} cityData - City Information
 */
export const addCity = (cityData) => (dispatch) => {
  dispatch({ type: ADD_CITY });
  axios
    .post('/cities/add', { ...cityData })
    .then((data) => {
      dispatch({
        type: ADD_CITY_SUCCESS,
        payload: 'success',
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ADD_CITY_ERROR,
        payload: err,
      });
    });
};

/**
 This action will update city information
 @args {object} cityData - City Information
 */
export const updateCity = (cityData) => (dispatch) => {
  dispatch({ type: UPDATE_CITY });
  axios
    .post('/cities/update', { ...cityData })
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: UPDATE_CITY_SUCCESS,
          payload: 'success',
        });
      } else {
        dispatch({
          type: UPDATE_CITY_ERROR,
          payload: data.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_CITY_ERROR,
        payload: err,
      });
    });
};
