import {
  ADD_ELECTION,
  ADD_ELECTION_ERROR,
  ADD_ELECTION_SUCCESS,
  FETCH_ELECTION,
  FETCH_ELECTION_SUCCESS,
  FETCH_ELECTION_ERROR,
  FETCH_ELECTION_CANDIDATES,
  FETCH_ELECTION_CANDIDATES_SUCCESS,
  FETCH_ELECTION_CANDIDATES_ERROR,
} from '../actions/ActionTypes';

const initialState = {
  elections: [],
  loading: false,
  error: '',
  electionCandidates: [],
  addElectionSuccess: false,
};

const electionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ELECTION: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ELECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        elections: action.payload,
      };
    }
    case FETCH_ELECTION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ADD_ELECTION: {
      return {
        ...state,
        loading: true,
        addElectionSuccess: false,
      };
    }

    case ADD_ELECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        addElectionSuccess: action.payload,
      };
    }

    case ADD_ELECTION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        addElectionSuccess: false,
      };
    }

    case FETCH_ELECTION_CANDIDATES: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ELECTION_CANDIDATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        electionCandidates: action.payload,
      };
    }
    case FETCH_ELECTION_CANDIDATES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default electionReducer;
