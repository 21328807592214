import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
//import { useHistory } from 'react-router-dom'; // no longer used (ccb)
import { sortBy } from 'lodash';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import {
  fetchElectionCandidates,
  fetchElections,
} from '../../redux/actions/Elections';
import { setSelectedCityId } from '../../redux/actions/Cities';
import Footer from '../Footer';

import MobileElectionInfo from './Mobile/ElectionInfo'
import DesktopElectionInfo from './Desktop/ElectionInfo'
import MobileCandidateCard from './Mobile/CandidateCard'
import DesktopCandidateCard from './Desktop/CandidateCard'

const City = ({ match }) => {  
  let primaryDate = '',
    electionDate = '',
    runOffDate = '';
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCityObj, setSelectedCityObj] = useState({});
  const [electionList, setElectionList] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(
    localStorage.getItem('mPos') || ''
  );
  const [eliminated, setEliminated] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(false);
  const dispatch = useDispatch();
  //const history = useHistory(); // no longer used (ccb)

  const { elections, electionCandidates, loading } = useSelector(
    (state) => state.election
  );
  const { cities, selectedCityId, selectedPositionId } = useSelector(
    (state) => state.city
  );
  const { theme } = useSelector((state) => state.global);
  const [mode, setMode] = useState(theme);

  /**
   * register-to-vote (rtv) -- extract url (aka registerlink) from global state
   */
  let rtvUrl = cities.filter((city) => city.name === selectedCity); // rtv
  rtvUrl.length === 0 ? rtvUrl = "" : rtvUrl = rtvUrl[0].registerlink; //rtv

  /**
   * it will add and remove resize event listener to window object to check window width
   */
  useEffect(() => {
    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize);
  }, []);

  /**
   * it sets mobile view if window width change
   */
  useEffect(() => {
    if (width <= 767) {
      localStorage.setItem('position', '');
      setIsMobileView(true);
    } else {
      localStorage.setItem('mPos', '');
      setSelectedPosition('');
      setIsMobileView(false);
    }
  }, [width]);

  /**
   * it sets the selected city id and position id in global store
   */
  useEffect(() => {
    const city = match?.params?.city;
    if (city) {
      let obj = {};
      setSelectedCity(city);
      const ind = cities.findIndex((c) => c.name === city);
      obj = { ...cities[ind] };
      if (!selectedCityId) {
        const idx = cities?.findIndex(
          (c) => c?.name?.toLowerCase() === city?.toLowerCase()
        );
        // if (idx > 0) {
        dispatch(
          setSelectedCityId(
            cities[idx]?._id,
            cities[idx]?.name,
            selectedPositionId
          )
        );
        obj = cities[idx];
        // }
      }
      setSelectedCityObj({ ...obj });
    }
  }, [
    dispatch,
    selectedCityId,
    selectedPositionId,
    match?.params?.city,
    cities,
  ]);

  /**
   * it fetches candidates based on selected city and position
   */
  useEffect(() => {
    if (isMobileView && selectedCityId) {
      dispatch(fetchElections(selectedCityId));
    } else if (!isMobileView && selectedCityId && selectedPositionId) {
      dispatch(fetchElectionCandidates(selectedCityId, selectedPositionId));
    } else if (selectedCityId) {
      dispatch(fetchElections(selectedCityId));
    }
  }, [dispatch, selectedCityId, isMobileView, selectedPositionId]);

  /**
   * it sets the positions and candidate list fetched from api in local variable in mobile view
   */
  useEffect(() => {
    if (elections.length > 0) {
      // let eArr = [];
      // if (selectedCityObj?.name?.includes('Seattle')) {
      //   eArr = orderBy(elections, ['position.name'], ['asc']);
      // } else {
      //   eArr = [...elections];
      // }
      setElectionList([...elections]);
    }
  }, [elections, selectedCityObj?.name]);

  /**
   * it sets light/dark mode
   */
  useEffect(() => {
    setMode(theme);
  }, [theme]);

  /**
   * it sets candidate list in desktop view
   */
  useEffect(() => {
    if (electionCandidates?.length > 0) {
      // let eArr = [];
      // if (selectedCityObj?.name?.includes('Seattle')) {
      //   eArr = orderBy(electionCandidates, ['position.name'], ['asc']);
      // } else {
      //   eArr = [...electionCandidates];
      // }
      setElectionList([...electionCandidates]);
    }
  }, [electionCandidates, selectedCityObj?.name]);

  /**
   * it sets value of width based on current window width
   */
  const onResize = () => {
    setWidth(window.innerWidth);
  };

  // ----------------------------- onSelectCandidate ---------------------------------
  const onSelectCandidate = (candidateId, candidateName) => {
    const candidateNameArr = candidateName?.split(' ');
    let name = '';
    if (candidateNameArr?.length > 2) {
      name = candidateNameArr[0] + candidateNameArr[2];
    } else {
      name = candidateNameArr[0] + candidateNameArr[1];
    }
    const url =
      name?.toLowerCase() +
      moment(electionDate)?.year() +
      match?.params?.city?.split(',')?.[0]?.toLowerCase();
    const cityName = match?.params?.city;
    // commented out history.push due to GHIssue #66 (ccb)
    // history.push(`/${cityName}/candidate/${candidateId}/${url}`, { cityName: match?.params?.city });
    return (`/${cityName}/candidate/${candidateId}/${url}`);
  };
  // ----------------------------- onSelectCandidate ---------------------------------

  /**
   * formats date according to design
   */
  if (electionList?.length > 0) {
    if (electionList[0]?.primaryDate) {
      primaryDate = moment(electionList[0]?.primaryDate).format('LL');
    }
    if (electionList[0]?.electionDate) {
      electionDate = moment(electionList[0]?.electionDate).format('LL');
    }
    if (electionList[0]?.runOffDate) {
      runOffDate = moment(electionList[0]?.runOffDate).format('LL');
    }
  }

  /**
   * filter candidates based on their withdrawl or eliminated status
   * @type {unknown[] | undefined}
   */

  let eliminatedCandidates = electionList[0]?.candidates?.filter(
    (c) => c.isWithdrawl || c.isEliminated
  );

  let cList = electionList[0]?.candidates?.filter(
    (c) => !c.isWithdrawl && !c.isEliminated
  );
  eliminatedCandidates = sortBy(eliminatedCandidates, ['candidateName']);
  cList = sortBy(cList, ['candidateName']);

  const getInitials = (name) => {
    return (
      `${name.split(' ')[0].substr(0, 1)}${name.split(' ')[1].substr(0, 1)}`
    )
  }

  return (
    <div className='mainsite'>
      <div className={`main ${mode}`}>
        {/* Desktop View starts here  */}
          {!isMobileView && (
            <img
              alt={'bg-img'}
              className={'mainimage'}
             src={
                mode === 'light'
                 ? selectedCityObj?.images?.web?.lightlist
                  : selectedCityObj?.images?.web?.darklist
              }
            />
         )}
        <div className='desktop-wrap'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-8 col-lg-12'>
                <div className='mb-5 ml-lg-4 ml-0  '>
                  <h1 className={`title-text mt-5 mb-3 ${mode}`}>
                    Candidates:
                  </h1>
                  {loading ? (
                    <div className={'ml-5 mt-15'}>
                      <Spinner
                        animation='border'
                        variant={mode === 'dark' ? 'light' : 'dark'}
                      />
                    </div>
                  ) : (
                    <div className='row'>
                      {(cList || [])?.map((c, index) => { // added index (mohan)
                        /**
                         * getting initials of candidate name
                         * @type {string}
                         */
                        return (
                          <DesktopCandidateCard key={index} // added index (mohan)
                            c={c}
                            mode={mode}
                            onSelectCandidate={onSelectCandidate}
                            initials={getInitials(c?.candidateName)}
                          />
                        );
                      })}
                    </div>
                  )}
                  {eliminatedCandidates?.length > 0 && (
                    <>
                      <h1 className='title-text mt-5 pt-5 mb-4'>
                        Withdrawn and Eliminated Candidates:
                      </h1>
                      <div className='row'>
                        {loading ? (
                          <div className={'ml-5 mt-15'}>
                            <Spinner
                              animation='border'
                              variant={mode === 'dark' ? 'light' : 'dark'}
                            />
                          </div>
                        ) : (
                          <>
                            {eliminatedCandidates?.map((c, index) => { // added index (mohan)
                              return (
                                <DesktopCandidateCard key={index} // added index (mohan)
                                  c={c}
                                  mode={mode}
                                  onSelectCandidate={onSelectCandidate}
                                  initials={getInitials(c?.candidateName)}
                                />
                              );
                            })}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <DesktopElectionInfo
                mode={mode}
                selectedCity={selectedCity}
                primaryDate={primaryDate}
                electionDate={electionDate}
                runOffDate={runOffDate}
                rtvUrl={rtvUrl}
              />
            </div>
          </div>
        </div>
        {/* Desktop View ends here  */}
        {/* Mobile View starts here  */}
        <div
          className={`slider-wrap ${mode}`}
          style={{
            backgroundImage: `url(${mode === 'light'
              ? selectedCityObj?.images?.mobile?.lightlist
              : selectedCityObj?.images?.mobile?.darklist
              })`,
          }}
        >
          <MobileElectionInfo
            mode={mode}
            selectedCity={selectedCity}
            primaryDate={primaryDate}
            electionDate={electionDate}
            runOffDate={runOffDate}
            rtvUrl={rtvUrl}
          />
        </div>
        <div className={'dropdown-container'}>
          {(electionList || [])
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((pos) => {
              let eliminatedCandidates = pos?.candidates?.filter(
                (c) => c.isWithdrawl || c.isEliminated
              );
              let cList = pos?.candidates?.filter(
                (c) => !c.isWithdrawl && !c.isEliminated
              );
              eliminatedCandidates = sortBy(eliminatedCandidates, ['candidateName']);
              cList = sortBy(cList, ['candidateName']);
              return pos?.position?.name === 'Mayor' ? (
                <Accordion
                  className='city-dropdown-close'
                  onSelect={(p) => {
                    localStorage.setItem('mPos', p);
                    setSelectedPosition(p);
                  }}
                  activeKey={selectedPosition}
                  key={pos?._id}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant='link'
                    eventKey={pos?._id}
                  >
                    <div className='d-flex flex-column align-items-start'>
                      <p className='m-0'>{pos?.position?.name}</p>
                      <p className={`m-0 candidates-number ${mode}`}>
                        {cList.length} Candidates
                      </p>
                    </div>
                    {selectedPosition === pos?._id ? (
                      <img className='up' src={up} alt='up' />
                    ) : (
                      <img className='down' src={down} alt='down' />
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={pos?._id}>
                    <Card.Body>
                      {(cList || [])?.map((c, index) => {  // added index (mohan)
                        return (
                          <MobileCandidateCard key={index} // added index (mohan)
                            c={c}
                            onSelectCandidate={onSelectCandidate}
                            initials={getInitials(c?.candidateName)}
                          />
                        );
                      })}
                      {eliminatedCandidates?.length > 0 && (
                        <Accordion
                          className='d-flex flex-column align-items-center justify-content-center mt-n1'
                          onSelect={(id) => {
                            setEliminated(id);
                          }}
                        >
                          <Accordion.Toggle
                            as={Button}
                            variant='link'
                            eventKey={pos?._id}
                            className='m-auto d-flex px-3 sub-menu-iteam '
                          >
                            <div>Withdrawn / Eliminated</div>
                            <div> {eliminated === pos?._id ? '-' : '+'}</div>
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            eventKey={pos?._id}
                            className='w-100'
                          >
                            <Card.Body>
                              {(eliminatedCandidates || [])?.map((c, index) => { // added index (mohan)

                                return (
                                  <MobileCandidateCard key={index} // added index (mohan)
                                    c={c}
                                    onSelectCandidate={onSelectCandidate}
                                    initials={getInitials(c?.candidateName)}
                                  />
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Accordion>
              ) : (
                ''
              );
            })}
          {(electionList || [])
            ?.sort((a, b) =>
              a.name.localeCompare(b.name, 'en', { numeric: true })
            )
            .map((pos) => {
              let eliminatedCandidates = pos?.candidates?.filter(
                (c) => c.isWithdrawl || c.isEliminated
              );
              let cList = pos?.candidates?.filter(
                (c) => !c.isWithdrawl && !c.isEliminated
              );
              eliminatedCandidates = sortBy(eliminatedCandidates, ['candidateName']);
              cList = sortBy(cList, ['candidateName']);
              return pos?.position?.name !== 'Mayor' && cList.length > 0 ? (
                <Accordion
                  className='city-dropdown-close'
                  onSelect={(p) => {
                    localStorage.setItem('mPos', p);
                    setSelectedPosition(p);
                  }}
                  activeKey={selectedPosition}
                  key={pos?._id}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant='link'
                    eventKey={pos?._id}
                  >
                    <div className='d-flex flex-column align-items-start'>
                      <p className='m-0'>{pos?.position?.name}</p>
                      <p className={`m-0 candidates-number ${mode}`}>
                        {cList.length} Candidates
                      </p>
                    </div>
                    {selectedPosition === pos?._id ? (
                      <img className='up' src={up} alt='up' />
                    ) : (
                      <img className='down' src={down} alt='down' />
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={pos?._id}>
                    <Card.Body>
                      {(cList || [])?.map((c, index) => { // added index (mohan)
                        return (
                          <MobileCandidateCard key={index} // added index (mohan)
                            c={c}
                            onSelectCandidate={onSelectCandidate}
                            initials={getInitials(c?.candidateName)}
                          />
                        );
                      })}
                      {eliminatedCandidates?.length > 0 && (
                        <Accordion
                          className='d-flex flex-column align-items-center justify-content-center mt-n1'
                          onSelect={(id) => {
                            setEliminated(id);
                          }}
                        >
                          <Accordion.Toggle
                            as={Button}
                            variant='link'
                            eventKey={pos?._id}
                            className='m-auto d-flex px-3 sub-menu-iteam '
                          >
                            <div>Withdrawn / Eliminated</div>
                            <div> {eliminated === pos?._id ? '-' : '+'}</div>
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            eventKey={pos?._id}
                            className='w-100'
                          >
                            <Card.Body>
                              {(eliminatedCandidates || [])?.map((c, index) => { // added index (mohan)
                                return (
                                  <MobileCandidateCard key={index} // added index (mohan)
                                    c={c}
                                    onSelectCandidate={onSelectCandidate}
                                    initials={getInitials(c?.candidateName)}
                                  />
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Accordion>
              ) : (
                ''
              );
            })}
        </div>
        {/* Mobile View ends here  */}
      </div>
      <Footer />
    </div>
  );
};
export default City;