import React, { useEffect, useState } from 'react';
import Icons from './Icons';
import { useSelector } from 'react-redux';

const Footer = () => {
  const { theme } = useSelector((store) => store.global);
  const [mode, setMode] = useState(theme);

  /**
   * set current theme
   */
  useEffect(() => {
    setMode(theme);
  }, [theme]);
  return (
    <div className={`footer-section ${mode}`}>
      <footer className={`footer__contents ${mode}`}>
        <div className='footer__icons'>
          <Icons mode={mode} />
        </div>
      </footer>
      <div className={`disclaimer__content ${mode}`}>
        <p className='disclaimer__text'>
          Purplely is not affiliated with any political party, candidate, or
          interest group
        </p>
      </div>
    </div>
  );
};
export default Footer;
