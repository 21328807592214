import React from 'react'; // useState not used (2022 ccb)
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddCityModal = (props) => {
  const {
    mode,
    modalShow,
    setModalShow,
    Close
  } = props;
  
  return (
    <div className='container-modal'>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        style={{ opacity: 1 }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <button data-dismiss='modal' class='close' type='button'>
            <span aria-hidden='true' onClick={() => setModalShow(false)}>
              <img src={Close} alt='close' />
            </span>
            <span className='sr-only'>Close</span>
          </button>
          <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
        </Modal.Header>
        <Modal.Body className={`content-modalBody ${mode}`}>
          <div>
            <h1 className={'modal-text-title'}>
              <p>Add</p> <p>Your</p> <p>City</p>
            </h1>
          </div>
          <div className='line-addCity'>
            <div className='line'></div>
          </div>
          <div className='content-modalText'>
            <p>
              If you want <span className='coloredWord'>Purplely</span> to
              cover local elections in your city, please email us at{' '}
              <a href='mailto:info@purplely.org' className='coloredLink'>
                info@purplely.org
              </a>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCityModal;
