// import choice from '../assets/images/choice.png';
import LightImage_Desktop from '../assets/images/light_homepage_desktop.jpg';
import DarkImage_Desktop from '../assets/images/dark_homepage_desktop.jpg';
import LightImage_Mobile from '../assets/images/light_homepage_mobile.png';
import DarkImage_Mobile from '../assets/images/dark_homepage_mobile.png';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function Landing() {
  const { theme } = useSelector((store) => store.global);
  const [mode, setMode] = useState(theme);
  /**
   * sets current theme
   */
  useEffect(() => {
    setMode(theme);
  }, [theme]);
  return (
    <div className='mainsite'>
      <>
      <div className={`main ${mode}`}>
        <img
          src={mode === 'light' ? LightImage_Desktop : DarkImage_Desktop}
          alt='choice'
          className={'mainimage choice__img desktop__img'}
        />
        <img
          src={mode === 'light' ? LightImage_Mobile : DarkImage_Mobile}
          alt='choice'
          className={'mainimage choice__img mobile__img'}
        />
        <div className={`landing__bio ${mode}`}>
          <div className='row'>
            <div className='row'>
              <div className='col-xl-6 side-line'>
                <h1>Local Elections Matter</h1>
              </div>
              <div className='col-xl-6'>
                <div className='text-section'>
                  <p>
                    Local Elections affect our daily lives but fewer than 30% of
                    eligible citizens are turning out to vote for our community
                    leaders.
                  </p>
                  <p>
                    Purplely is here to help you! We collect policy information
                    from local election candidates in your city, then aggregate
                    and relay these details to you all in one place.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer /></> 
    </div>
  );
}

export default Landing;
