import {
  ADD_ELECTION,
  ADD_ELECTION_ERROR,
  ADD_ELECTION_SUCCESS,
  FETCH_ELECTION,
  FETCH_ELECTION_ERROR,
  FETCH_ELECTION_SUCCESS,
  FETCH_ELECTION_CANDIDATES,
  FETCH_ELECTION_CANDIDATES_SUCCESS,
  FETCH_ELECTION_CANDIDATES_ERROR,
} from '../ActionTypes';
import axios from '../../axios';

/**
 This action will fetch elections list based on City Id
 @args {string} cityId - City Id
 */
export const fetchElections = (cityId) => (dispatch) => {
  dispatch({ type: FETCH_ELECTION });
  axios
    .get(`/elections/${cityId}`)
    .then((data) => {
      dispatch({
        type: FETCH_ELECTION_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FETCH_ELECTION_ERROR,
        payload: err,
      });
    });
};

/**
 This action will fetch elections list based on City Id and Position Id
 @args {string} cityId - City Id
 @args {string} positionId - Position Id
 */
export const fetchElectionCandidates = (cityId, positionId) => (dispatch) => {
  dispatch({ type: FETCH_ELECTION_CANDIDATES });
  return axios
    .get(`/elections/${cityId}/${positionId}`)
    .then((data) => {
      dispatch({
        type: FETCH_ELECTION_CANDIDATES_SUCCESS,
        payload: data.data,
      });
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FETCH_ELECTION_CANDIDATES_ERROR,
        payload: err,
      });
    });
};

/**
 This action will add Election
 @args {object} electionData - Election Information
 */
export const addElection = (electionData) => (dispatch) => {
  dispatch({ type: ADD_ELECTION });
  axios
    .post('/elections/add', { ...electionData })
    .then((data) => {
      dispatch({
        type: ADD_ELECTION_SUCCESS,
        payload: 'success',
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ADD_ELECTION_ERROR,
        payload: err,
      });
    });
};
