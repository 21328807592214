import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import light_about_us_desktop from '../assets/images/light_about_us_desktop.png';
import light_about_voted_desktop from '../assets/images/light_about_voted_desktop.png';
import light_about_us_mobile from '../assets/images/light_about_us_mobile.png';
import light_about_voted_mobile from '../assets/images/light_about_voted_mobile.png';
import dark_about_us_desktop from '../assets/images/dark_about_us_desktop.png';
import dark_about_voted_desktop from '../assets/images/dark_about_voted_desktop.png';
import dark_about_us_mobile from '../assets/images/dark_about_us_mobile.png';
import dark_about_voted_mobile from '../assets/images/dark_about_voted_mobile.png';
import Footer from './Footer';
import '../sass/components/_about-us.scss';

const AboutUs = () => {
  const { theme } = useSelector((state) => state.global);
  const [mode, setMode] = useState(theme);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(false);

  /**
   * add/remove resize event listener
   */
  useEffect(() => {
    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize);
  }, []);

  /**
   * set mobile view based on window width
   */
  useEffect(() => {
    if (width <= 767) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [width]);

  /**
   * sets current theme of app
   */
  useEffect(() => {
    setMode(theme);
  }, [theme]);

  /**
   * set width of screen based on window width
   */
  const onResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className='mainsite'>
      <div className={`main ${mode}`}>
        <div className='about_us'>
          {isMobileView && (
            <img
              className='mainimage desktop__img'
              src={
                mode === 'light'
                  ? light_about_us_desktop
                  : dark_about_us_desktop
              }
              alt='About Us'
            />
          )}
          <div className='row overview'>
            <div className='col-xl-6 justify-content-between left'>
              <div className='title'>
                <span className='text_about'>About</span>
                <p className='text_purplely'>Purplely</p>
              </div>
              <img
                className='mainimage mobile__img'
                src={
                  mode === 'light'
                    ? light_about_us_mobile
                    : dark_about_us_mobile
                }
                alt='About Us'
              />
              <div className='divider'>
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
              </div>
            </div>
            <div className='col-xl-5 right py-4'>
              <div className='mobile dot' />
              <div className='mb-4 mobile bar' />
              <p>
                Local elections are critically important. Every day, over half a
                million local elected officials make important and influential
                decisions about core services like police, fire department,
                transportation, housing, and clean drinking water.
              </p>
              <div className='mb-4 mobile bar' />
              <p>
                As cities experience robust growth, they become virtual
                laboratories for positive civic change, especially as
                federal-level political gridlock continues.
              </p>
              <div className='mb-4 mobile bar' />
              <p className='mb-0'>
                According to a New York Times study, despite the incredible
                impact local elected officials can have on people’s lives, only
                27% of eligible voters participated in local elections
                nationwide.
              </p>
              <div className='mt-4 mobile bar' />
              <div className='mobile dot' />
            </div>
            <img
              className='mainimage desktop__img'
              src={
                mode === 'light'
                  ? light_about_voted_desktop
                  : dark_about_voted_desktop
              }
              alt='Voted'
            />
            <img
              className='mainimage mobile__img'
              src={
                mode === 'light'
                  ? light_about_voted_mobile
                  : dark_about_voted_mobile
              }
              alt='Voted'
            />
            <div className='info'>
              <p>
                <span className='text_purplely purplely'>Purplely</span>{' '}
                provides voters information on all the candidates in local
                elections in one place.{' '}
              </p>
              <p>
                We collect information directly from candidates and share it
                with voters in a way they can easily access and understand.{' '}
              </p>
              <p>
                <span className='text_purplely purplely'>Purplely</span> makes
                information access easier for voters and helps increases voter
                turnout in our local election.
              </p>
            </div>
            <div className='notes'>
              *Note*: Purplely is not affiliated with any political party,
              candidate, or interest group. Purplely only provides unbiased
              information, not opinions.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
