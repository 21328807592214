import React from 'react'

const ElectionInfo = (props) => {
  const {
    mode,
    selectedCity,
    primaryDate,
    electionDate,
    runOffDate,
    rtvUrl,
  } = props;

  return (
    <div className={`data-display-wrap ${mode}`}>
      <div className={'city-stats'}>{selectedCity}</div>
      {primaryDate && (
        <div className={'city-stats-data'}>
          <div className='label text-uppercase'>Primary: </div>
          <div className='data py-1'>{primaryDate || '--'}</div>
        </div>
      )}
      {electionDate && (
        <div className={'city-stats-data'}>
          <div className='label text-uppercase'>General: </div>
          <div className='data py-1'>{electionDate || '--'}</div>
        </div>
      )}
      {runOffDate && (
        <div className={'city-stats-data'}>
          <div className='label text-uppercase'>Runoff: </div>
          <div className='data py-1'>{runOffDate || '--'}</div>
        </div>
      )}
      <div className={'register-to-vote city-stats-data pt-4'} >
        <div className={`rtv rtv-mobile list-card e-list-card m-auto px-3 py-3 my-2 ${mode}`}>
          <a href={rtvUrl} alt={selectedCity} rel="noreferrer" target="_blank">
            <div className=' d-flex align-items-center justify-content-between'>
              <p className='rtv-p rtv-p-mobile text-uppercase display-4 m-auto'>Register To Vote</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ElectionInfo;