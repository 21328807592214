import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
// import imgDarkArrowNext from '../../assets/images/dark-right-arrow.png';
// import imgLightArrowNext from '../../assets/images/light-right-arrow.png';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/**
 This component will describe the Videos of user
 @props {array} videoUrls - Video URLs
 */
const Video = ({ videoUrls }) => {
  const isMobileView = window.innerWidth < 762;

  const { theme } = useSelector((store) => store.global);
  const [mode, setMode] = useState(theme);

  /**
   * This will handle the changes in the theme
   * @dependency {string} theme - The theme information
   */
  useEffect(() => {
    setMode(theme);
  }, [theme]);

  useEffect(() => {
  }, [mode]);
  /**
   * This will provide custom Next Arrow
   * @dependency {object} props - Props Information
   */
  const ArrowNext = (props) => {
    const { to, onClick } = props;
    return (
      <Button
        type='button'
        onClick={onClick}
        className={`slider-btn-next`}
        aria-label={to}
      >{">"}
      </Button>
    );
  };
  const ArrowPrev = (props) => {
    const { to, onClick } = props;
    return (
      <Button
        type='button'
        onClick={onClick}
        className={`slider-btn-prev`}
        aria-label={to}
      >{"<"}
      </Button>
    );
  };
  /**
   * This describes settings of the slider
   */
  const settings = {
    dots: false,
    infinite: false,
    prevArrow: videoUrls.length > 3 || isMobileView ? <ArrowPrev /> : <></>,
    nextArrow: videoUrls.length > 3 || isMobileView ? <ArrowNext /> : <></>,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    adaptiveHeight: true,
  };

  return (
    <div id='video-div' className='column'>
      <div className='tab-title'>
        <h1 className='ml-0'>Videos</h1>
      </div>
      <div className='bio-description'>
        <Slider {...settings}>
          {videoUrls?.map((url, index) => {
            return (
              <ReactPlayer
                className='react-player'
                key={index}
                height={isMobileView ? 160 : 150}
                width={isMobileView ? 240 : 270}
                url={url}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Video;
