import React from "react";
import { useHistory } from "react-router"; // GHIssue #66 (ccb)

const CandidateCard = (props) => {
  const { c, mode, onSelectCandidate, initials } = props;
  const history = useHistory(); // GHIssue #66 (ccb)

  return (    
    <div className="col-lg-6" key={c?._id}>
      {/* div to anchor tag in order to enable open-in-new-tab feature (GHIssue #66)(ccb) */}
      <div
        //href={onSelectCandidate(c?._id, c?.candidateName)}
        className={`list-card candidate-card d-flex align-items-center px-4 py-3 my-3 ${mode}`}
        onClick={() => history.push(onSelectCandidate(c?._id, c?.candidateName), {didNavigate: true})}
        onContextMenu={() => {window.open(onSelectCandidate(c?._id, c?.candidateName), "_blank")}}
      >
        <div className="d-flex">
          {c?.profile ? (
            <img
              className="mr-1 rounded-circle"
              src={c?.profile}
              height={55}
              width={55}
              alt={"profile"}
            />
          ) : (
            <div className={"avatar-circle"}>
              <div className={"initials"}>{initials}</div>
            </div>
          )}
        </div>
        <div className="card-details">
          <p className="">{c?.candidateName}</p>
          {c?.party && <div className="party-name">{c?.party}</div>}
          <div />
        </div>
        {/*MD: Starts for non-mobile view*/}
        {c.isWinner && <div className="banner">Winner</div>}
        {/*MD: Ends*/}
      </div>
    </div>
  );
};

export default CandidateCard;
