import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { orderBy, concat } from 'lodash';
import PositionList from './PositionList';

const CityList = (props) => {
  const {
    cityList,
    expandedPosition,
    onSetExpandedPosition,
    selectedPosition,
    selectedCity,
    onPositionSelect,
    setModalShow
  } = props;

  const visibleCities = cityList.filter((c) => c?.visible); // Filtering visible cities
  let readyCities = visibleCities.filter((c) => c?.ready); // Filtering ready cities
  let notReadyCities = visibleCities.filter((c) => !c?.ready); // Filtering not ready cities
  readyCities = orderBy(readyCities, ['name'], ['desc']); // Sorting ready cities in desc order
  notReadyCities = orderBy(notReadyCities, readyCities, ['name'], ['asc']); // Sorting non-ready cities in asc order
  const finalCityArr = concat(readyCities, notReadyCities);
  const allCitiesOrder = orderBy(finalCityArr, ['name'], ['asc']);
  
  return (
    <>
      <li className='nav-text'>
        {allCitiesOrder?.map((c) => {
          return (
            <Accordion
              className='menu-dropdown-wrap w-100'
              key={c?._id}
              >
              <Accordion.Toggle
                as={Button}
                variant='link'
                eventKey={c?._id}
                onClick={() => {
                  onSetExpandedPosition(c?._id);
                }}
                className={`menu-dropdown2 w-100 text-left d-flex align-items-center flex-wrap justify-content-between ${
                  expandedPosition.includes(c?._id) && 'btn-open'
                }`}
              >
                <span className='city-name'>{c?.name}</span>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey={c?._id}
                className='dropdown-menu'
              >
                <PositionList
                  c={c}
                  selectedPosition={selectedPosition}
                  selectedCity={selectedCity}
                  onPositionSelect={onPositionSelect}
                />
              </Accordion.Collapse>
            </Accordion>
          );
        })}
      </li>
      <div className='division-line'></div>
      <li className='list-unstyled' onClick={() => setModalShow(true)}>
        {/* <div className='footer__addcity addCity w-100 justify-content-center w-100'>
          + Add City
        </div> */}
      </li>
    </>
  );
};

export default CityList;
