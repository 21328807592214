import React from 'react';
import { FiFacebook, FiTwitter, FiMail } from 'react-icons/fi';

const Icons = ({ mode }) => (
  <div className={`_icons ${mode}`}>
    <a
      href={'https://www.facebook.com/Purplely.org/'}
      target={'_blank'}
      rel='noopener noreferrer'
      className={''}
    >
      <FiFacebook />
    </a>
    <a
      href={'https://twitter.com/purplelyhq?s=11'}
      target={'_blank'}
      rel='noopener noreferrer'
      className={''}
    >
      <FiTwitter />
    </a>
    <a
      href={'mailto: info@purplely.org'}
      target={'_blank'}
      rel='noopener noreferrer'
    >
      <FiMail />
    </a>
  </div>
);

export default Icons;
