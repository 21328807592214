import {
  FETCH_CANDIDATE,
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATE_ERROR,
  ADD_CANDIDATE,
  ADD_CANDIDATE_SUCCESS,
  ADD_CANDIDATE_ERROR,
  FETCH_CANDIDATE_BY_ID,
  FETCH_CANDIDATE_BY_ID_SUCCESS,
  FETCH_CANDIDATE_BY_ID_ERROR,
} from '../ActionTypes';

import axios from '../../axios';

/**
 This action will fetch candidate list based on City Id
 @args {string} cityId - City Id
 */
export const fetchCandidates = (cityId) => (dispatch) => {
  dispatch({ type: FETCH_CANDIDATE });
  axios
    .post('/candidates', {
      cityId,
    })
    .then((data) => {
      dispatch({
        type: FETCH_CANDIDATE_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FETCH_CANDIDATE_ERROR,
        payload: err,
      });
    });
};

/**
 This action will fetch candidate Information based on Candidate Id
 @args {string} CandidateId - Candidate Id
 */
export const fetchCandidateInfoById = (candidateId) => (dispatch) => {
  dispatch({ type: FETCH_CANDIDATE_BY_ID });
  axios
    .get(`/candidates/${candidateId}`)
    .then((data) => {
      dispatch({
        type: FETCH_CANDIDATE_BY_ID_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FETCH_CANDIDATE_BY_ID_ERROR,
        payload: err,
      });
    });
};

/**
 This action will add Candidate
 @args {object} candidateData - Candidate Information
 */
export const addCandidate = (candidateData) => (dispatch) => {
  dispatch({ type: ADD_CANDIDATE });
  axios
    .post('/candidates/add', { ...candidateData })
    .then((data) => {
      dispatch({
        type: ADD_CANDIDATE_SUCCESS,
        payload: 'success',
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ADD_CANDIDATE_ERROR,
        payload: err,
      });
    });
};
