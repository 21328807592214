import {
  ADD_POSITION,
  ADD_POSITION_SUCCESS,
  ADD_POSITION_ERROR,
} from '../actions/ActionTypes';

const initialState = {
  addPositionSuccess: false,
  loading: false,
  error: '',
};

const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POSITION: {
      return {
        ...state,
        loading: true,
        addPositionSuccess: false,
      };
    }
    case ADD_POSITION_SUCCESS: {
      return {
        ...state,
        loading: false,
        addPositionSuccess: action.payload,
      };
    }
    case ADD_POSITION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        addPositionSuccess: false,
      };
    }
    default: {
      return state;
    }
  }
};
export default policyReducer;
