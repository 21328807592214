import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Close from '../../assets/images/Close.png';
import { setSelectedCityId } from '../../redux/actions/Cities';
import { setTheme } from '../../redux/actions/Global';
import { fetchElectionCandidates } from '../../redux/actions/Elections';
// import { orderBy, concat } from 'lodash'; // (2022 ccb)
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import useColorScheme from './themeComponent';
import AddCityModal from '../../modals/AddCityModal';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import 'bootstrap/dist/css/bootstrap.min.css';

const Navigation = (props) => {
  const [sidebar, setSidebar] = useState(false);
  // const [cityList, setCityList] = useState([]); // (2022 ccb)
  const [expandedPosition, setExpandedPosition] = useState([]);
  const [expandedYear, setExpandedYear] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const { cities } = useSelector((store) => store.city); // removed loading (2022 ccb)
  const { theme } = useSelector((state) => state.global);
  const [mode, setMode] = useState('');
  // const dark = useColorScheme('dark');

  /**
   * fetch cities and add/remove resize event listener
   */
  useEffect(() => {
    // dispatch(fetchCities());
    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * set mobile view on the basis of window width
   */
  useEffect(() => {
    if (width <= 767) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [width]);

  /**
   * set citylist in sidebar
   */
  // useEffect(() => {
  //   if (cities) {
  //     const visibleCities = cities.filter((c) => c?.visible); // Filtering visible cities
  //     let readyCities = visibleCities.filter((c) => c?.ready); // Filtering ready cities
  //     let notReadyCities = visibleCities.filter((c) => !c?.ready); // Filtering not ready cities
  //     readyCities = orderBy(readyCities, ['name'], ['desc']); // Sorting ready cities in desc order
  //     notReadyCities = orderBy(notReadyCities, readyCities, ['name'], ['asc']); // Sorting non-ready cities in asc order
  //     const finalCityArr = concat(readyCities, notReadyCities);
  //     const allCitiesOrder = orderBy(finalCityArr, ['name'], ['asc']);

  //     setCityList([...allCitiesOrder]);
  //   }
  // }, [cities]);

  /**
   * implemented to apply dark mode based on chrome settings
   */
  // useEffect(() => {
  //   const theme = dark ? 'dark' : 'light';
  //   setMode(theme);
  //   if (dark) {
  //     dispatch(setTheme('dark'));
  //   } else {
  //     dispatch(setTheme('light'));
  //   }
  // }, [dark]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMode(theme);
  }, [theme]);

  /**
   * set width based on window width
   */
  const onResize = () => {
    setWidth(window.innerWidth);
  };

  /**
   * set selected city id in global store
   * redirects to coming soon page if the city is not ready for elections
   * @param city
   */
  const onCitySelect = (city) => {
    dispatch(setSelectedCityId(city?._id, city?.name));
    if (!city?.ready) {
      history.push('/coming-soon');
    }
  };

  /**
   * set position id in global store
   * fetch candidates based on selected city and position
   * @param city
   * @param positionId
   */
  const onPositionSelect = (city, positionId) => {
    dispatch(setSelectedCityId(city?._id, city?.name, positionId));
    if (city?.ready) {
      dispatch(fetchElectionCandidates(city?._id, positionId)).then((d) => {
        if (d && d.length > 0 && d[0].candidates && d[0].candidates.length <= 0) {
          history.push('/coming-soon', { cityName: city?.name });
        }
      });
    }
    setSelectedPosition(positionId);
    setSelectedCity(city?._id);

    const selectedCity = cities.find((c) => c._id === city?._id);
    if (!selectedCity.ready) {
      history.push('/coming-soon', { cityName: city?.name });
    } else {
      history.push(`/city/${city?.name}`);
    }
  };

  /**
   * toggle sidebar
   */
  const showSidebar = () => setSidebar(!sidebar);

  /**
   * toggle light and dark mode
   * @param isDarkMode
   */
  const onModeChange = (isDarkMode = false) => {
    if (isDarkMode) {
      setMode('dark');
      dispatch(setTheme('dark'));
    } else {
      setMode('light');
      dispatch(setTheme('light'));
    }
  };

  /**
   * redirects to about us page
   */
  const onAboutClick = () => {
    history.push('/about-us');
  };

  /**
   * push/pop the position id of cities which are expanded
   * @param pId
   */
  const onSetExpandedPosition = (pId) => {
    const arr = [...expandedPosition];
    if (arr?.includes(pId)) {
      const idx = arr.findIndex((p) => p === pId);
      if (idx >= 0) {
        arr.splice(idx, 1);
      }
    } else {
      arr.push(pId);
    }
    setExpandedPosition([...arr]);
  };

  /**
   * push/pop the year which are expanded
   * @param pId
   */
   const onSetExpandedYear = (year) => {
    const arr = [...expandedYear];
    if (arr?.includes(year)) {
      const idx = arr.findIndex((y) => y === year);
      if (idx >= 0) {
        arr.splice(idx, 1);
      }
    } else {
      arr.push(year);
    }
    setExpandedYear([...arr]);
  };
  
  // const tempCityList = [
  //   {
  //     _id: "tempID1",
  //     name: "Annapolis, MD"
  //   },
  //   {
  //     _id: "tempID2",
  //     name: "San Diego, CA"
  //   }
  // ]
  
  return (
    <>
      <AddCityModal
        mode={mode}
        modalShow={modalShow}
        setModalShow={setModalShow}
        Close={Close}
      />

      {isMobileView ? (
        <MobileView
          mode={mode}
          onModeChange={onModeChange}
          showSidebar={showSidebar}
          history={history}
          sidebar={sidebar}
          Close={Close}
          // cityList={cityList}
          // tempCityList={tempCityList}
          onCitySelect={onCitySelect}
          onAboutClick={onAboutClick}
          onSetExpandedPosition={onSetExpandedPosition}
          expandedPosition={expandedPosition}
          onSetExpandedYear={onSetExpandedYear}
          expandedYear={expandedYear}
        />
      ) : (
        <DesktopView
          mode={mode}
          history={history}
          onAboutClick={onAboutClick}
          sidebar={sidebar}
          showSidebar={showSidebar}
          onSetExpandedYear={onSetExpandedYear}
          onSetExpandedPosition={onSetExpandedPosition}
          expandedPosition={expandedPosition}
          expandedYear={expandedYear}
          // cityList={cityList}
          // tempCityList={tempCityList}
          selectedPosition={selectedPosition}
          selectedCity={selectedCity}
          onPositionSelect={onPositionSelect}
          setModalShow={setModalShow}
          onModeChange={onModeChange}
        />
      )}
    </>
  );
};
export default Navigation;
