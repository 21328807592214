/* Scroll Back To The Top Feature*/
/* STT (CCB) */

import { useEffect, useState } from "react";
import { FaCaretSquareUp } from "react-icons/fa";
import "../sass/components/_scroll-to-top.scss";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top-bg">
      <FaCaretSquareUp
        className="scroll-to-top"
        onClick={scrollToTop}
        style={{ display: isVisible ? "inline" : "none" }}
      />
    </div>
  );
};

export default ScrollToTop;
