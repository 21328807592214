import {
  FETCH_CITY,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_ERROR,
  FETCH_CITY_AND_POSITION,
  FETCH_CITY_AND_POSITION_ERROR,
  FETCH_CITY_AND_POSITION_SUCCESS,
  SET_SELECTED_CITY,
  ADD_CITY,
  ADD_CITY_ERROR,
  ADD_CITY_SUCCESS,
  UPDATE_CITY,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_ERROR,
  RESET_EMAIL_ERROR,
} from '../actions/ActionTypes';

const initialState = {
  cities: [],
  loading: false,
  error: '',
  selectedCityId: '',
  selectedPositionId: localStorage.getItem('position') || '',
  addCitySuccess: false,
  updateCitySuccess: false,
  theme: 'light',
  cityPositionList: [],
};

const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CITY: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_CITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        cities: action.payload,
      };
    }
    case FETCH_CITY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case FETCH_CITY_AND_POSITION: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_CITY_AND_POSITION_SUCCESS: {
      return {
        ...state,
        loading: false,
        cityPositionList: action.payload,
      };
    }
    case FETCH_CITY_AND_POSITION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case ADD_CITY: {
      return {
        ...state,
        loading: true,
        addCitySuccess: false,
      };
    }
    case ADD_CITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        addCitySuccess: action.payload,
      };
    }
    case ADD_CITY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        addCitySuccess: false,
      };
    }
    case UPDATE_CITY: {
      return {
        ...state,
        loading: true,
        updateCitySuccess: false,
      };
    }
    case UPDATE_CITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        updateCitySuccess: action.payload,
      };
    }
    case RESET_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
        updateCitySuccess: false,
      };
    }
    case UPDATE_CITY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateCitySuccess: false,
      };
    }
    case SET_SELECTED_CITY: {
      return {
        ...state,
        selectedCityId: action.payload?.cityId,
        selectedPositionId: action.payload?.positionId,
      };
    }
    default: {
      return state;
    }
  }
};
export default cityReducer;
