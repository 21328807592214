import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Form } from 'react-bootstrap';
import { isString } from 'lodash';

import Footer from './Footer';
import { fetchElections } from '../redux/actions/Elections';
import { updateCity } from '../redux/actions/Cities';
import { RESET_EMAIL_ERROR } from '../redux/actions/ActionTypes';

import '../sass/components/_coming-soon.scss';

/**
   This component will be rendered if position information of particular city is not ready
   @props {object} props - Props value
 */
const ComingSoon = (props) => {
  const cityName = localStorage.getItem('city');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [electionList, setElectionList] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [selectedCityObj, setSelectedCityObj] = useState({});
  const dispatch = useDispatch();
  const {
    cities,
    selectedCityId,
    updateCitySuccess = false,
    error = '',
  } = useSelector((store) => store.city);
  const { theme } = useSelector((store) => store.global);
  const [mode, setMode] = useState(theme);
  const { elections } = useSelector((store) => store.election);
  let primaryDate = '',
    electionDate = '',
    runOffDate = '';
  const isMobileView = window.innerWidth < 991;

  /**
   * This will handle the changes in the selectedCityId and cities
   * @dependency {string} selectedCityId - The Selected City Id
   * @dependency {array} cities - Cities Information
   */
  useEffect(() => {
    if (!selectedCityId && cities.length > 0) {
      const selectedCity = cities?.find(
        (c) => c?.name?.toLowerCase() === cityName?.toLowerCase()
      );
      setCityId(selectedCity._id);
      setSelectedCityObj({ ...selectedCity });
    } else {
      const selectedCity = cities?.find((c) => c?._id === selectedCityId);
      setCityId(selectedCityId);
      setSelectedCityObj({ ...selectedCity });
    }
  }, [selectedCityId, cityName, cities]);

  /**
   * register-to-vote (rtv) -- extract url (aka registerlink) from global state
   */
  let rtvUrl = cities.filter((city) => city.name === cityName);
  rtvUrl.length === 0 ? rtvUrl = "" : rtvUrl = rtvUrl[0].registerlink;

  /**
   * This will handle the changes in the cityId and dispatch
   * @dependency {string} cityId - The City Id
   * @dependency {object} dispatch - The Dispatch
   */
  useEffect(() => {
    if (isString(cityId)) {
      if (cityId !== '') {
        dispatch(fetchElections(cityId));
      }
    }
  }, [cityId, dispatch]);

  /**
   * This will handle the changes in the theme
   * @dependency {string} theme - The theme information
   */
  useEffect(() => {
    setMode(theme);
  }, [theme]);

  /**
   * This will handle the changes in elections
   * @dependency {array} elections - The Elections Information
   */
  useEffect(() => {
    if (elections.length > 0) {
      setElectionList([...elections]);
    }
  }, [elections]);

  /**
   * This will handle the changes in the error
   * @dependency {string} error - The Error information
   */
  useEffect(() => {
    if (error) {
      setEmailError('Please enter a valid email');
    }
  }, [error]);

  /**
   * This will handle the changes in the props and dispatch
   * @dependency {object} props - The props information
   * @dependency {object} dispatch - The dispatch information
   */
  useEffect(() => {
    setEmailError('');
    dispatch({
      type: RESET_EMAIL_ERROR,
    });
  }, [props, dispatch]);

  /**
   * This will validate email
   */
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  /**
   * This will dispatch an action to save email if email is valid
   */
  const handleNotifyMe = (e) => {
    e.preventDefault();

    if (cityId) {
      if (validateEmail(email)) {
        dispatch(
          updateCity({
            cityId: cityId,
            email,
          })
        );
      } else {
        setEmailError('Please enter a valid email.');
      }
    } else {
      setEmailError('Please select a city.');
    }
    setEmail('');
  };

  if (electionList?.length > 0) {
    if (electionList[0]?.primaryDate) {
      primaryDate = moment(electionList[0]?.primaryDate).format('MMMM D, yyyy');
    }
    if (electionList[0]?.electionDate) {
      electionDate = moment(electionList[0]?.electionDate).format(
        'MMMM D, yyyy'
      );
    }
    if (electionList[0]?.runOffDate) {
      runOffDate = moment(electionList[0]?.runOffDate).format('MMMM D, yyyy');
    }
  }

  return (
    <div className={`mainsite  ${mode}`}>
      <div className='main'>
        <div
          className={`background-wrap ${mode}`}
          style={{
            backgroundImage: ` linear-gradient(
              90deg,
              var(--theme-gradient-color),
              var(--theme-gradient-color)
            ),
            url(${mode === 'light'
                ? isMobileView
                  ? selectedCityObj?.images?.mobile?.lightcomingsoon
                  : selectedCityObj?.images?.web?.lightcomingsoon
                : isMobileView
                  ? selectedCityObj?.images?.mobile?.darkcomingsoon
                  : selectedCityObj?.images?.web?.darkcomingsoon
              })`,
          }}
        >
          <div className='coming-soon-main-wrap d-block d-md-flex align-items-center h-100 flex-wrap'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-12 divider'>
                  <div className={`coming-soon-section`}>
                    <div
                      className={`px-0 px-md-3 px-xl-5 custom-mob-section align-items-sm-center ${mode}`}>
                      <p className={`title-text my-md-2 my-5 pt-3 pt-md-0 text-uppercase`}>
                        Coming Soon
                      </p>
                      {/* This Div is only for Mobile View */}
                      <div className='d-md-none d-flex flex-column px-5 px-md-5 mx-0 mx-md-5 w-100 mb-5 mb-md-0'>
                        <p className={`my-2 text-uppercase election-section`}>
                          Election Info
                        </p>
                        <div className=''>
                          {/* primaryDate */}
                          {primaryDate && (
                            <div className='d-flex align-items-center mb-n4'> {/* added mb-n4 (ccb) */}
                              <label className='width-fix d-md-none d-flex mb-0'>
                                PRIMARY:{' '}
                              </label>
                              <div className='list-card w-100 my-2'>
                                <div className='card-details d-flex align-items-center justify-content-between'>
                                  {/* comented-out to prevent duplicate text (ccb) */}
                                  {/* {isMobileView && (
                                  <p className='text-uppercase m-0'>PRIMARY:</p>
                                )} */}
                                  <div className='w-100 text-center'>
                                    {primaryDate || '--'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* electionDate */}
                          {electionDate && (
                            <div className='d-flex align-items-center mb-n4'> {/* added mb-n4 (ccb) */}
                              <label className='width-fix d-md-none d-flex mb-0'>
                                General:{' '}
                              </label>
                              <div className='list-card w-100 my-2'>
                                <div className='card-details d-flex align-items-center justify-content-between'>
                                  {/* comented-out to prevent duplicate text (ccb) */}
                                  {/* {isMobileView && (
                                    <p className='text-uppercase m-0'>General:</p>
                                  )} */}
                                  <div className='w-100 text-center'>
                                    {electionDate || '--'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div>
                            {/* runOffDate */}
                            {runOffDate && (
                              <div className='d-flex align-items-center mb-n4'> {/* added mb-n4 (ccb) */}
                                <label className='width-fix d-md-none d-flex mb-0'>
                                  RunOff:{' '}
                                </label>
                                <div className='list-card w-100 my-2'>
                                  <div className='card-details d-flex align-items-center justify-content-between'>
                                    {/* comented-out to prevent duplicate text (ccb) */}
                                    {/* {!isMobileView && (
                                      <p className='text-uppercase m-0'>RunOff:{' '}</p>
                                    )} */}
                                    <div className='w-100 text-center'>
                                      {runOffDate || '--'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* begin - register-to-vote (rtv) - mobile*/}
                            <div className={'register-to-vote city-stats-data pb-0 pt-1 mt-3'} > {/* added mt-3 (ccb) */}
                              <div className={`rtv rtv-mobile rtv-mobile-cs list-card e-list-card m-auto px-3 py-2 my-2 ${mode}`}>
                                <a href={rtvUrl} alt={cityName} rel="noreferrer" target="_blank">
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <p className='rtv-p rtv-p-mobile text-uppercase display-4 m-auto' >Register To Vote</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            {/* end - register-to-vote (rtv) - mobile */}
                          </div>
                        </div>
                      </div>
                      <p className={`mx-4 get-notified`}>
                        Get notified when {cityName?.split(',')?.[0]} is ready
                      </p>
                      <div className='w-100 border-0'>
                        <div className='mb-2 px-5 px-md-0 position-relative'>
                          <input
                            type='text'
                            placeholder='YOUR EMAIL'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='email-wrap'
                          />
                          <Button
                            className='shadow-none text-uppercase notify-btn'
                            type='submit'
                            onClick={handleNotifyMe}
                          >
                            Notify Me
                          </Button>
                        </div>
                      </div>
                      <Form.Label
                        className={`form-label ml-0 ml-md-5 ml-lg-5 ml-xl-4`}
                      >
                        {updateCitySuccess ? (
                          isMobileView ? (
                            <div className='d-flex align-items-center flex-md-row flex-column'>
                              <p className='m-0'>Thank you!</p>
                              <p className='m-0'>
                                You will be notifed when{' '}
                                {cityName?.split(',')?.[0]} is ready
                              </p>
                            </div>
                          ) : (
                            `Thank you! You will be notifed when ${cityName?.split(',')?.[0]
                            } is ready`
                          )
                        ) : (
                          emailError
                        )}
                      </Form.Label>
                    </div>
                  </div>
                </div>
                {/* This Div is only for Desktop View */}
                <div className='d-none d-md-block col-xl-5 col-lg-6 col-12 m-auto'>
                  <div className='px-0 px-md-4 text-center'>
                    <p className={`my-2 text-uppercase font-weight-bold election-section ${mode}`}>
                      Election Info
                    </p>
                    <div className=''>
                      {/* primaryDate */}
                      {primaryDate && (
                        <div className='d-flex align-items-center'>
                          <label className='width-fix d-md-none d-flex mb-0'>
                            Primary:{' '}
                          </label>
                          <div className='list-card w-100 my-3'>
                            <div className='card-details d-flex align-items-center justify-content-between'>
                              <p className='text-uppercase ml-3'>Primary:</p> {/* m-0 to ml-3 (ccb) */}
                              <div className='w-100 mr-3 text-right'> {/* added mr-3 (ccb) */}
                                {primaryDate || '--'}
                              </div>
                            </div>
                          </div>
                        </div>)}
                      {/* electionDate */}
                      {electionDate && (
                        <div className='d-flex align-items-center'>
                          <label className='width-fix d-md-none d-flex mb-0'>
                            General:{' '}
                          </label>
                          <div className='list-card w-100 my-3'>
                            <div className='card-details d-flex align-items-center justify-content-between'>
                              <p className='text-uppercase ml-3'>General:</p> {/* m-0 to ml-3 (ccb) */}
                              <div className='w-100 mr-3 text-right'> {/* added mr-3 (ccb) */}
                                {electionDate || '--'}
                              </div>
                            </div>
                          </div>
                        </div>)}
                      <div>
                        {/* runOffDate */}
                        {runOffDate && (
                          <div className='d-flex align-items-center'>
                            <label className='width-fix d-md-none d-flex mb-0'>
                              RunOff:{' '}
                            </label>
                            <div className='list-card w-100 my-3'>
                              <div className='card-details d-flex align-items-center justify-content-between'>
                                <p className='text-uppercase ml-3'>RunOff:</p> {/* m-0 to ml-3 (ccb) */}
                                <div className='w-100 mr-3 text-right'> {/* added mr-3 (ccb) */}
                                  {runOffDate || '--'}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* begin - register-to-vote (rtv) - desktop */}
                        <div className='d-flex align-items-center mt-2 register-to-vote'>
                          <div className={`rtv rtv-desktop list-card px-3 w-100 py-3 my-2 ${mode}`}>
                            <a href={rtvUrl} alt={cityName} target='_blank' rel='noreferrer'>
                              <p className='rtv-p rtv-p-desktop text-uppercase display-4 m-auto'>Register To Vote</p>
                            </a>
                          </div>
                        </div>
                        {/* end - register-to-vote (rtv) - desktop*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComingSoon;
