import React from 'react';
import { Link } from 'react-router-dom';
import { orderBy, concat } from 'lodash';

const CityList = (props) => {
  const {
    mode,
    cityList,
    onCitySelect,
    showSidebar
  } = props;

  const visibleCities = cityList.filter((c) => c?.visible); // Filtering visible cities
  let readyCities = visibleCities.filter((c) => c?.ready); // Filtering ready cities
  let notReadyCities = visibleCities.filter((c) => !c?.ready); // Filtering not ready cities
  readyCities = orderBy(readyCities, ['name'], ['desc']); // Sorting ready cities in desc order
  notReadyCities = orderBy(notReadyCities, readyCities, ['name'], ['asc']); // Sorting non-ready cities in asc order
  const finalCityArr = concat(readyCities, notReadyCities);
  const allCitiesOrder = orderBy(finalCityArr, ['name'], ['asc']);
  
  return (
    <div className='sidebar-overflow'>
      {allCitiesOrder.map((item) => {
        return (
          <li
            key={item._id}
            className={`test nav-text light-bg p-0 mobile-view-item ${mode}`}
            onClick={() => {
              onCitySelect(item);
            }}
          >
            <Link to={`/city/${item.name}`} className='menu-bars py-4' onClick={showSidebar}>
              <p className='m-0'>{item.name}</p>
            </Link>
          </li>
        );
      })}
    </div>
  );
};

export default CityList;
