import React from 'react';

/**
   This component will describe the contact information of user
   @props {string} email - Email information
   @props {string} mode - The theme information
   @props {string} username - FirstName of User
 */
const ContactInfo = ({ email, mode, username, middleName }) => {
  let contactName = username;
  if (username === 'M.') {
    contactName = middleName;
  }

  return (
    <div id='contact-div' className='column'>
      <div className='tab-title '>
        <h1 className='py-0 py-md-2'>Contact Info</h1>
      </div>
      <p
        className={`bio-description mb-5 ${mode} contact-info`}
      >
        For questions, comments, and concerns, please contact {contactName} at{' '}
        <span className={`contact-email ml-1 ${mode}`}>{email}</span>.
      </p>
    </div>
  );
};

export default ContactInfo;
