export const QUERY_FOR_ELECTION = 'QUERY_FOR_ELECTION';

export const QUERY_FOR_CANDIDATES = 'QUERY_FOR_CANDIDATES';

export const FETCH_CITY = 'FETCH_CITY';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_ERROR = 'FETCH_CITY_ERROR';
export const SET_SELECTED_CITY = 'SET_SELECTED_CITY';

export const FETCH_CITY_AND_POSITION = 'FETCH_CITY_AND_POSITION';
export const FETCH_CITY_AND_POSITION_SUCCESS = 'FETCH_CITY_AND_POSITION_SUCCESS';
export const FETCH_CITY_AND_POSITION_ERROR = 'FETCH_CITY_AND_POSITION_ERROR';

export const ADD_CITY = 'ADD_CITY';
export const ADD_CITY_SUCCESS = 'ADD_CITY_SUCCESS';
export const ADD_CITY_ERROR = 'ADD_CITY_ERROR';

export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_ERROR = 'UPDATE_CITY_ERROR';

export const FETCH_CANDIDATE = 'FETCH_CANDIDATE';
export const FETCH_CANDIDATE_SUCCESS = 'FETCH_CANDIDATE_SUCCESS';
export const FETCH_CANDIDATE_ERROR = 'FETCH_CANDIDATE_ERROR';

export const FETCH_CANDIDATE_BY_ID = 'FETCH_CANDIDATE_BY_ID';
export const FETCH_CANDIDATE_BY_ID_SUCCESS = 'FETCH_CANDIDATE_BY_ID_SUCCESS';
export const FETCH_CANDIDATE_BY_ID_ERROR = 'FETCH_CANDIDATE_BY_ID_ERROR';

export const FETCH_ELECTION = 'FETCH_ELECTION';
export const FETCH_ELECTION_SUCCESS = 'FETCH_ELECTION_SUCCESS';
export const FETCH_ELECTION_ERROR = 'FETCH_ELECTION_ERROR';

export const FETCH_ELECTION_CANDIDATES = 'FETCH_ELECTION_CANDIDATES';
export const FETCH_ELECTION_CANDIDATES_SUCCESS =
  'FETCH_ELECTION_CANDIDATES_SUCCESS';
export const FETCH_ELECTION_CANDIDATES_ERROR =
  'FETCH_ELECTION_CANDIDATES_ERROR';

export const ADD_POSITION = 'ADD_POSITION';
export const ADD_POSITION_SUCCESS = 'ADD_POSITION_SUCCESS';
export const ADD_POSITION_ERROR = 'ADD_POSITION_ERROR';

export const ADD_CANDIDATE = 'ADD_CANDIDATE';
export const ADD_CANDIDATE_SUCCESS = 'ADD_CANDIDATE_SUCCESS';
export const ADD_CANDIDATE_ERROR = 'ADD_CANDIDATE_ERROR';

export const ADD_ELECTION = 'ADD_ELECTION';
export const ADD_ELECTION_ERROR = 'ADD_ELECTION_ERROR';
export const ADD_ELECTION_SUCCESS = 'ADD_ELECTION_SUCCESS';

export const SET_THEME = 'SET_THEME';
export const RESET_EMAIL_ERROR = 'RESET_EMAIL_ERROR';
export const RESET_CONTACT_INFO = 'RESET_CONTACT_INFO';
