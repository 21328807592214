import React from 'react';
// import Elections2022 from './Desktop/Elections2022';
// import Elections2021 from './Desktop/Elections2021';
import Elections from './Desktop/Elections';
import DarkModeChange from './Desktop/DarkModeChange';
import TopNavBar from './Desktop/TopNavBar';

const DesktopView = (props) => {
  const {
    mode,
    history,
    onAboutClick,
    sidebar,
    showSidebar,
    onSetExpandedYear,
    onSetExpandedPosition,
    expandedPosition,
    expandedYear,
    // cityList,
    // tempCityList,
    selectedPosition,
    selectedCity,
    onPositionSelect,
    setModalShow,
    onModeChange
  } = props;
  
  return (
    <>
          <TopNavBar
            mode={mode}
            history={history}
            onAboutClick={onAboutClick}
          />
          <nav className={sidebar ? `nav-menu active ${mode}` : `nav-menu ${mode}`}>
            <div className='nav-menu-items position-relative' onClick={showSidebar}>
              {/* <Elections2022 
                cityList={tempCityList}
                onSetExpandedPosition={onSetExpandedPosition}
                expandedPosition={expandedPosition}
              /> 
              <Elections2021
                cityList={cityList}
                onSetExpandedPosition={onSetExpandedPosition}
                expandedPosition={expandedPosition}
                selectedPosition={selectedPosition}
                selectedCity={selectedCity}
                onPositionSelect={onPositionSelect}
                setModalShow={setModalShow}
              /> */}
              <Elections
                onSetExpandedYear={onSetExpandedYear}
                onSetExpandedPosition={onSetExpandedPosition}
                expandedPosition={expandedPosition}
                expandedYear={expandedYear}
                selectedPosition={selectedPosition}
                selectedCity={selectedCity}
                onPositionSelect={onPositionSelect}
                setModalShow={setModalShow}
              />
            </div>
            <DarkModeChange
              mode={mode}
              onModeChange={onModeChange}
            />
          </nav>
        </>
  );
};

export default DesktopView;
