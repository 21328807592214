import { SET_THEME } from '../ActionTypes';

/**
 This action will save theme value in redux store
 @args {string} theme - The theme information
 */
export const setTheme = (theme) => (dispatch) => {
  localStorage.setItem('theme', theme);
  dispatch({
    type: SET_THEME,
    payload: theme,
  });
};
