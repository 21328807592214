import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom'; // added Switch (jem)
import '../sass/theme.scss';
import TagManager from "react-gtm-module"; // google analytics - GTM
import 'bootstrap/dist/css/bootstrap.min.css';
import '../sass/style.scss';
import ScrollToTop from './ScrollToTop'; // STT (ccb)
import Navigation from './Navigation/Navigation';
import Landing from './Landing';
import AboutUs from './AboutUs';
import City from './City/City';
import Candidate from './CandidateLanding';
// import Forms from './Forms'; not currently needed (jame)
import ComingSoon from './ComingSoon';
import { fetchCities, fetchCitiesWithPositions } from '../redux/actions/Cities';

const App = () => {
  const [SelectedCity, UpdateSelectedCity] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCities());
    dispatch(fetchCitiesWithPositions());
  }, [dispatch]);

  // google analytics - GTM code
  const tagManagerArgs = { gtmId: "GTM-PVR3JNS" };
  TagManager.initialize(tagManagerArgs)

  return (
    <>
    <div>
      <BrowserRouter>
        <Navigation UpdateSelectedCity={UpdateSelectedCity} />
        {/* begin - refactored code for catch-all feature (jem) */}
        <Switch>
          <Route
            path='/'
            exact={true}
            render={(props) => (
              <Landing
                {...props}
                SelectedCity={SelectedCity}
                UpdateSelectedCity={UpdateSelectedCity}
              />
            )}
          />
          <Route path='/about-us' exact component={AboutUs} />
          <Route path='/coming-soon' exact component={ComingSoon} />
          <Route
            path='/:city/candidate/:candidateId/:candidateName'
            exact
            component={Candidate}
          />
          {/* not currently used or needed (jame) */}
          {/* <Route path='/forms' exact component={Forms} /> */}
          <Route
            path={`/city/:city`}
            exact
            render={(props) => (
              <City
                {...props}
                SelectedCity={SelectedCity}
                UpdateSelectedCity={UpdateSelectedCity}
              />
            )}
          />
          <Route
            path='/*'
            exact={true}
            render={(props) => (
              <Landing
                {...props}
                SelectedCity={SelectedCity}
                UpdateSelectedCity={UpdateSelectedCity}
              />
            )}
          />
        </ Switch>
        {/* end - refactored code for catch-all feature (jem) */}
      </BrowserRouter>
    </div>
    {/* STT (ccb) */}
    <ScrollToTop />
    </>
  );
};

export default App;
