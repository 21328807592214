import { combineReducers } from 'redux';

import CandidateReducer from './CandidateReducer';
import CityReducer from './CityReducer';
import PositionReducer from './PositionReducer';
import ElectionReducer from './ElectionReducer';
import ThemeReducer from './ThemeReducer';

export default combineReducers({
  candidate: CandidateReducer,
  city: CityReducer,
  position: PositionReducer,
  election: ElectionReducer,
  global: ThemeReducer,
});
