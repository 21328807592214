import React from 'react';
import ReactDOM from 'react-dom';
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import GA4React from 'ga-4-react';

import reducers from './redux/reducers';
import App from './components/App';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const ga4react = new GA4React('G-98YP3PKHVG');

ga4react.initialize().then(
  (ga4) => {
    ga4.pageview('/');
    ga4.gtag('event', 'pageview', '/');
  },
  (err) => {
    console.error(err);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
