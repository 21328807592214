import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import PlusIcon from '../../assets/images/plus.svg';
import MinusMobileIcon from '../../assets/images/minus-mobile-icon.png';
import { HiExternalLink } from 'react-icons/hi';

/**
   This component will describe the Policies of user
   @props {object} policies - Policies information
   @props {string} username - FirstName of User
   @props {string} mode - The theme information
   @props {boolean} isProvided - The isProvided information
 */
const Policies = ({
  userName,
  mode,
  policies,
  isProvided,
  policyHyperLink = null,
  isContactVisible = true,
}) => {
  const [expandedPolicy, setExpandedPolicy] = useState([]);
  const isMobileView = window.innerWidth < 762;

  /**
   * This will handle the changes in the policies and isMobileView
   * @dependency {object} policies - The candidate policies
   * @dependency {boolean} isMobileView - Is Mobile view information
   */
  useEffect(() => {
    if (!isMobileView && policies) {
      setExpandedPolicy([Object.keys(policies)?.[0]]);
    }
  }, [policies, isMobileView]);

  /**
   * This will handle the expansion/collapse of the policies accordion
   * @params {string} policy - The selected accordian event key
   */
  const handlePolicyExpansion = (policy) => {
    let newExpandedPolicies = [...expandedPolicy];
    if (newExpandedPolicies?.includes(policy)) {
      const idx = newExpandedPolicies.findIndex((p) => p === policy);
      newExpandedPolicies.splice(idx, 1);
    } else {
      newExpandedPolicies.push(policy);
    }
    setExpandedPolicy([...newExpandedPolicies]);
  };

  return (
    <div
      id='policy-div'
      className={`column policy-wrap ${!isContactVisible && 'p-bottom'}`}
    >
      <div className='tab-title'>
        <h1 className='mb-0'>Policies</h1>
        {policies && (
          <p className='mb-0'>
            {policyHyperLink ? (
              <a
                rel='noreferrer'
                target={'_blank'}
                href={policyHyperLink}
                className={`text-decoration-none ${mode}}`}
              >
                {isProvided}
                <HiExternalLink className={'ml-2 mb-1'} />
              </a>
            ) : (
              <>{isProvided}</>
            )}
          </p>
        )}
      </div>
      <div>
        {policies ? (
          policies &&
          Object.keys(policies)?.map((policy, index) => {
            return (
              <Accordion
                onSelect={(k) => {
                  handlePolicyExpansion(k || policy);
                }}
                activeKey={expandedPolicy.filter((p) => p === policy)[0]}
                key={`policy-card-${index}`}
              >
                <Card className='policy-card'>
                  <Card.Header
                    className={
                      expandedPolicy?.includes(policy)
                        ? `active-header ${mode}`
                        : `inactive-header ${mode}`
                    }
                  >
                    <Accordion.Toggle eventKey={policy} className='w-100'>
                      <div className='tab-icon-wrap d-flex align-items-center justify-content-between'>
                        <div>{policy}</div>
                        <div className='expansion-icon'>
                          {expandedPolicy?.includes(policy) ? (
                            isMobileView ? (
                              '-'
                            ) : (
                              <img src={MinusMobileIcon} alt='Collapse' />
                            )
                          ) : isMobileView ? (
                            '+'
                          ) : (
                            <img src={PlusIcon} alt='Expand' />
                          )}
                        </div>
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={policy}>
                    <Card.Body className={`policy-data ${mode}`}>
                      <ul>
                        {policies?.[policy]?.map((p, idx) => {
                          return typeof p !== 'string' ? (
                            Object.keys(p)?.map((innerArr, idx1) => {
                              return (
                                <>
                                  <div className='sub-bullet-title'>
                                    {innerArr}
                                  </div>
                                  <li key={idx1} className='sub-bullet'>
                                    {p?.[innerArr]?.map((a, idx2) => {
                                      return <li key={idx2}>{a}</li>;
                                    })}
                                  </li>
                                </>
                              );
                            })
                          ) : (
                            <li key={idx}>{p}</li>
                          );
                        })}
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            );
          })
        ) : (
          // removed px-md-4, mx-3: when candidate has no policies, text should not be indented (jame)
          <p className={`bio-description px-0 mx-md-5 mb-5 policy-null ${mode}`}>
            We don't have policies information from {userName}. We are waiting
            to hear back.
          </p>
        )}
      </div>
    </div>
  );
};

export default Policies;
