import React from 'react';

const DarkModeChange = (props) => {
  const {
    mode,
    onModeChange
  } = props;

  return (
    <div className='d-flex align-items-center justify-content-center p-3'>
      <p className={`mx-3 dark-text ${mode}`}>Dark Mode</p>
      <div className='position-relative center-switch '>
        <label className='switch '>
          <input
            type='checkbox'
            checked={mode === 'dark'}
            onChange={(e) => onModeChange(e.target.checked)}
          />
          <span className='slider round' />
        </label>
      </div>
    </div>
  );
};

export default DarkModeChange;
