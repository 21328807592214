import React, { useEffect } from "react"; // added useEffect for GTM
import TagManager from "react-gtm-module"; // google analytics - GTM
import { useSelector } from "react-redux"; // google analytics - GTM

/**
  This component will describe the bio of user
  @props {string} description - Bio Description
  @props {string} mode - The theme information
*/
const Bio = ({ description, mode }) => {
  /** google analytics, GTM */
  const candidateName = useSelector((state) => state.candidate.candidateInfo.candidateName);
  let candidateCity = useSelector((state) => state.election.electionCandidates);
  candidateCity.length === 0 ? candidateCity = "" : candidateCity = candidateCity[0].name;

  /**
   * This will convert string into Raw HTML
   * @args {string} text - The Bio Description
   */
  const rawMarkup = (text) => {
    return { __html: text };
  };

  /*
   * code for Google Tag Manager (GTM) and pushing event info to dataLayer for GTM
   */
  useEffect(() => {
    const isURL = window.location.href;
    const tagManagerArgs = {
      gtmId: "GTM-PVR3JNS",
      dataLayer: {
        event: "candidatePageview",
        url: isURL,
        name: candidateName,
        city: candidateCity,
      },
    };
    // candidateName is undefined initially and creates "duplicate" lines in dataLayer
    candidateName && TagManager.initialize(tagManagerArgs);
  }, [candidateName, candidateCity]);

  return (
    <div id='bio-div' className='column'>
      <div className='tab-title bio-title'>
        <h1 className={`py-md-2 ${mode}`}>Bio</h1>
      </div>
      <p
        className={`bio-description ${mode}`}
        dangerouslySetInnerHTML={rawMarkup(description)}
      />
    </div>
  );
};

export default Bio;
