import React from "react";
import { useHistory } from "react-router-dom"; // GHIssue #66 (ccb)

function CandidateCard(props) {
  let history = useHistory(); // GHIssue #66 (ccb)
  const { c, onSelectCandidate, initials } = props;

  return (
    <div
      className={"main-wrap-accordion d-flex"}
      key={c._id}
      // modified line below to accomodate fix for GHIssue #66 (ccb)
      onClick={() => history.push(onSelectCandidate(c?._id, c?.candidateName), {didNavigate: true})}
    >
      <div className="avatar-winner">
        {c?.profile ? (
          <>
            <img
              className={"mr-1 ml-4 rounded-circle"}
              src={c.profile}
              height={37}
              width={37}
              alt={"profile"}
            />
            {c.isWinner && <div className="banner">Winner</div>}
          </>
        ) : (
          <>
            <div className={"m-avatar-circle mr-1"}>
              <span className={"m-initials"}>{initials}</span>
            </div>
            {c.isWinner && <div className="banner">Winner</div>}
          </>
        )}
      </div>
      <div className={"ml-5 d-flex flex-column"}>
        <p className="m-0">{c.candidateName}</p>
        {c?.party && <div className="party-name">{c?.party}</div>}
        <div />
      </div>
    </div>
  );
}

export default CandidateCard;
