import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import CityList from './CityList';

const Elections = (props) => {
  const {
    mode,
    onCitySelect,
    onSetExpandedYear,
    expandedYear,
    showSidebar
  } = props;

  const [ elections, setElectionsList ] = useState([]);

  const { cityPositionList } = useSelector(store => store.city);

  useEffect(() => {
      if (cityPositionList) {
        setElectionsList([...cityPositionList]);
        if (cityPositionList.length > 0)
            onSetExpandedYear(cityPositionList[0]._id);
      }
  // eslint-disable-next-line
  }, [cityPositionList]);
  
  return (
      <>
        {
            (elections || []).map((el, ind) => {
                return (
                    <Accordion className='menu-dropdown-wrap w-100' key={ind} defaultActiveKey={1}>
                        <Accordion.Toggle
                        as={Card.Header}
                        onClick={() => {
                            onSetExpandedYear(el._id);
                        }}
                        eventKey={ind + 1}
                        className={`menu-dropdown-mobile w-100 text-left d-flex align-items-center  flex-wrap justify-content-between ${
                            expandedYear.includes(el._id) && 'btn-open'
                        }`}
                        >
                        <span className='city-name'>{el._id} Elections</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={ind + 1}>
                        <CityList
                            cityList={el.data}
                            mode={mode}
                            onCitySelect={onCitySelect}
                            showSidebar={showSidebar}
                        />
                        </Accordion.Collapse>
                    </Accordion>
                )
            })
        }
      </>
  );
};

export default Elections;