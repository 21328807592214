import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import CityList from './CityList';

const Elections = (props) => {
  const {
    // cityList, (2022 ccb)
    onSetExpandedYear,
    onSetExpandedPosition,
    expandedPosition,
    expandedYear,
    selectedPosition,
    selectedCity,
    onPositionSelect,
    setModalShow
  } = props;

  const [elections, setElectionsList] = useState([]);
  const { cityPositionList } = useSelector((store) => store.city); // removed loading (2022 ccb)

  useEffect (() => {
    setElectionsList([...cityPositionList]);
    if (cityPositionList.length > 0)
        onSetExpandedYear(cityPositionList[0]._id);
  // eslint-disable-next-line
  }, [cityPositionList]);

  return (
      <>
        {
            (elections || []).map((el, ind) => {
                return <Accordion className='menu-dropdown-wrap w-100' key={ind} defaultActiveKey={1}>
                    <Accordion.Toggle
                        as={Card.Header}
                        onClick={() => {
                            onSetExpandedYear(el._id);
                        }}
                        eventKey={ind+1}
                        className={`menu-dropdown w-100 text-left d-flex align-items-center  flex-wrap justify-content-between ${
                            expandedYear.includes(el._id) && 'btn-open'
                        }`}
                    >
                        <span className='city-name'>{el._id} Elections</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                    eventKey={ind+1}
                    className='dropdown-menu'
                    >
                        <CityList
                            cityList={el.data}
                            expandedPosition={expandedPosition}
                            onSetExpandedPosition={onSetExpandedPosition}
                            selectedPosition={selectedPosition}
                            selectedCity={selectedCity}
                            onPositionSelect={onPositionSelect}
                            setModalShow={setModalShow}
                        />
                    </Accordion.Collapse>
                    </Accordion>
            })
        }
      </>
  );
};

export default Elections;
