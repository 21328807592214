import React from 'react';
import { FaBars } from 'react-icons/fa';
import { FiSun, FiMoon } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const TopNavBar = (props) => {
  const {
    mode,
    onModeChange,
    showSidebar,
    history
  } = props;

  return (
    <div className={`navbar ${mode}`}>
      <Link to='#' className='menu-bars'>
        <FaBars size={22} onClick={showSidebar} />
      </Link>
      <div className='navbar-title'>
        <div className='brand__name'>
          <div onClick={() => history.push('/')}>Purplely</div>
        </div>
        <div className='mode-change-btn'>
          {mode === 'light' ? (
            <FiMoon onClick={() => onModeChange(true)} />
          ) : (
            <FiSun onClick={() => onModeChange(false)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
