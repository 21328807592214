import React from 'react'

const ElectionInfo = (props) => {
  const {
    mode,
    selectedCity,
    primaryDate,
    electionDate,
    runOffDate,
    rtvUrl,
  } = props;

  return (
    <div className='col-lg-12 col-xl-4'>
      <p className='title-text text-center mt-5 mb-3 text-uppercase font-weight-bold'>
        Election Info:{' '}
      </p>
      <div className='mb-5 mr-0 mr-xl-4'>
        <div className='row'>
          {primaryDate && (
            <div className='col-lg-12'>
              <div
                className={`list-card e-list-card  w-100 px-3 py-2 my-2 ${mode}`}>
                <div className='card-details d-flex align-items-center justify-content-between'>
                  <p className='text-uppercase font-18 m-0'>Primary:</p>
                  <div>{primaryDate}</div>
                </div>
              </div>
            </div>
          )}
          {electionDate && (
            <div className='col-lg-12'>
              <div
                className={`list-card e-list-card  w-100 px-3 py-2 my-2 ${mode}`}>
                <div className='card-details d-flex align-items-center justify-content-between'>
                  <p className='text-uppercase font-18 m-0'>General:</p>
                  <div>{electionDate}</div>
                </div>
              </div>
            </div>
          )}
          {runOffDate && (
            <div className='col-lg-12'>
              <div
                className={`list-card e-list-card w-100 px-3 py-2 my-2 ${mode}`}>
                <div className='card-details d-flex align-items-center justify-content-between'>
                  <p className='text-uppercase font-18 m-0'>
                    Runoff:
                  </p>
                  <div>{runOffDate}</div>
                </div>
              </div>
            </div>
          )}
          <div className='register-to-vote col-lg-12 pt-4'>
            <div className={`rtv rtv-desktop w-100 px-3 py-3 my-2 ${mode}`}>
              <a href={rtvUrl} alt={selectedCity} rel='noreferrer' target='_blank'>
                <div className='card-details d-flex align-items-center justify-content-between'>
                  <p className='rtv-p rtv-p-desktop text-uppercase display-4 m-auto'>Register To Vote</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectionInfo;