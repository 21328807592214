import React from 'react';

const TopNavBar = (props) => {
  const {
    mode,
    history,
    onAboutClick
  } = props;
  
  return (
    <div className={`navbar ${mode}`}>
      <div className='navbar-title'>
        <div className='brand__name'>
          <div onClick={() => history.push('/')}>Purplely</div>
        </div>
        <div className='menu-link'>
          <ul>
            <li onClick={onAboutClick}>
              <div onClick={() => history.push('/about-us')}>About Us</div>
            </li>
            <li className='pr-0'>
              <a
                href={'mailto: info@purplely.org'}
                target={'_blank'}
                rel='noopener noreferrer'
              >Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
