import { QUERY_FOR_CANDIDATES } from '../actions/ActionTypes';
import {
  FETCH_CANDIDATE,
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATE_ERROR,
  ADD_CANDIDATE,
  ADD_CANDIDATE_SUCCESS,
  ADD_CANDIDATE_ERROR,
  FETCH_CANDIDATE_BY_ID,
  FETCH_CANDIDATE_BY_ID_SUCCESS,
  FETCH_CANDIDATE_BY_ID_ERROR,
  RESET_CONTACT_INFO,
} from '../actions/ActionTypes';

const initialState = {
  candidates: [],
  loading: false,
  error: '',
  addCandidateSuccess: false,
  candidateInfo: {},
};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUERY_FOR_CANDIDATES: {
      return [...state, action.payload];
    }
    case FETCH_CANDIDATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_CANDIDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        candidates: action.payload,
      };
    }
    case FETCH_CANDIDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case FETCH_CANDIDATE_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_CANDIDATE_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        candidateInfo: action.payload?.[0],
      };
    }
    case FETCH_CANDIDATE_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ADD_CANDIDATE: {
      return {
        ...state,
        loading: true,
        addCandidateSuccess: false,
      };
    }
    case ADD_CANDIDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        addCandidateSuccess: action.payload,
      };
    }
    case ADD_CANDIDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        addCandidateSuccess: false,
      };
    }
    case RESET_CONTACT_INFO: {
      return {
        ...state,
        loading: false,
        candidateInfo: {},
      };
    }

    default: {
      return state;
    }
  }
};
export default candidateReducer;
