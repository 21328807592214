import React from 'react';

const PositionList = (props) => {
  const {
    c,
    selectedPosition,
    selectedCity,
    onPositionSelect
  } = props;
  
  return (
    <>
      {(c?.position || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((pos) => {
          return pos?.name === 'Mayor' ? (
            <div
              className={`dropdown-item ${
                pos?._id === selectedPosition &&
                selectedCity === c?._id &&
                'active'
              }`}
              onClick={() => onPositionSelect(c, pos._id)}
              key={pos._id}
            >
              {pos?.name}
            </div>
          ) : (
            ''
          );
        })}
      {(c?.position || [])
        .sort((a, b) =>
          a.name.localeCompare(b.name, 'en', {
            numeric: true,
          })
        )
        .map((pos) => {
          return pos?.name !== 'Mayor' ? (
            <div
              className={`dropdown-item ${
                pos?._id === selectedPosition &&
                selectedCity === c?._id &&
                'active'
              }`}
              onClick={() => onPositionSelect(c, pos._id)}
              key={pos._id}
            >
              {pos?.name}
            </div>
          ) : (
            ''
          );
        })}
    </>
  );
};

export default PositionList;
