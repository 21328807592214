import React from 'react';
import { Link } from 'react-router-dom';
import TopNavBar from './Mobile/TopNavBar';
// import SideBarFooter from './Mobile/SideBarFooter'; // (2022 ccb)
// import Elections2022 from './Mobile/Elections2022';
// import Elections2021 from './Mobile/Elections2021';
import Elections from './Mobile/Elections';

const MobileView = (props) => {
  const {
    mode,
    onModeChange,
    showSidebar,
    history,
    sidebar,
    Close,
    // cityList,
    // tempCityList,
    onCitySelect,
    // onAboutClick, // (2022 ccb)
    onSetExpandedPosition,
    onSetExpandedYear,
    expandedPosition,
    expandedYear
  } = props;

  return (
    <>
      <TopNavBar
        mode={mode}
        onModeChange={onModeChange} 
        showSidebar={showSidebar}
        history={history}
      />
      <nav className={sidebar ? `nav-menu active ${mode}` : `nav-menu ${mode}`}>
        <ul className='nav-menu-items position-relative'>
          <li className={`navbar-toggle mobile-view-item ${mode}`}>
            <Link to='#' className='menu-bars nav-header' onClick={showSidebar}>
              <div className='d-flex align-items-center justify-content-between w-100 mt-2 '>
                <p className='mb-0'>Cities</p>
                <div className='d-flex justify-content-end'>
                  <img src={Close} alt='close' />
                </div>
              </div>
            </Link>
          </li>

          {/* <Elections2022
            cityList={tempCityList}
            mode={mode}
            onCitySelect={onCitySelect}
            onSetExpandedPosition={onSetExpandedPosition}
            expandedPosition={expandedPosition}
            showSidebar={showSidebar}
          />

          <Elections2021
            cityList={cityList}
            mode={mode}
            onCitySelect={onCitySelect}
            onSetExpandedPosition={onSetExpandedPosition}
            expandedPosition={expandedPosition}
            showSidebar={showSidebar}
          /> */}

          <Elections
            mode={mode}
            onCitySelect={onCitySelect}
            onSetExpandedPosition={onSetExpandedPosition}
            onSetExpandedYear={onSetExpandedYear}
            expandedPosition={expandedPosition}
            expandedYear={expandedYear}
            showSidebar={showSidebar}
          />

          {/* <SideBarFooter onAboutClick={onAboutClick} /> */}
          {/* 2022 ccb */}
          <div onClick={showSidebar} className="mobile-sidebar-footer">
            <p onClick={() => history.push(`/about-us`)}>About Us</p>
          </div>
        </ul>
      </nav>
    </>
  );
};

export default MobileView;
